<template>
    <div></div>
</template>
<script type>
import { getCode_api } from "@/api/common";
import Cookies from "js-cookie";
import store from '../../store/index.js'
import * as apiCommon from "@/api/common";
import { Toast } from 'vant';
export default {
    data() {
        return {};
    },
    created() {
        this.getCode();
        Toast.loading({
            message: '加载中...',
            forbidClick: true,
        });
    },
    methods: {
        getCode() {
            let redirect_uri = encodeURIComponent(
                store.state.URL
            );
            let url = window.location.href;
            let time = new Date().getTime()
            if (url.indexOf("code") == -1) {
                window.location.replace("https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx70deea58f0c566b2&redirect_uri=" +
                    redirect_uri +
                    `&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect&t=${time}`);
            }
            let codeUrl = JSON.parse(this.queryString(url));
            let code = codeUrl.code;
            this.getUserInfo(code);
        },
        queryString(url) {
            let arr = []; //存储参数的数组
            let res = {}; //存储最终JSON结果对象
            arr = url.split("?")[1].split("&");
            for (let i = 0, len = arr.length; i < len; i++) {
                //如果有等号，则执行赋值操作
                if (arr[i].indexOf("=") != -1) {
                    let str = arr[i].split("="); //str=[a,1];
                    res[str[0]] = str[1];
                } else {
                    //没有等号，则赋予空值
                    res[arr[i]] = "";
                }
            }
            res = JSON.stringify(res); //转化为JSON字符串
            return res;
        },
        //获取用户信息
        async getUserInfo(code) {
            let data = { code: code };
            getCode_api(data)
                .then(res => {
                    if (res.code == 0) {
                        Cookies.set("token", res.data.access_token);
                        this.applyUser();
                    } else {
                        this.applyUser();
                    }
                })
        },
        async applyUser() {
            let res = await apiCommon.getApplyUser();
            if (res.code == 0) {
                if (res.data.user.member_id != 0) { // 绑定了账号但是没有成为会员
                    if (res.data.user.union_member != 0) {
                        // 已经成为会员
                        this.$router.push("/newLogin/applicationSuccess");
                    } else {
                        // 兑换时长成为会员
                        this.$router.push("/newLogin/application");
                    }
                } else {
                    this.$router.push("/lador/index");
                }
            }
        },
    }
};
</script>
<style scoped lang="css">
.indexpage {
    display: flex;
    align-items: center;
    justify-content: center;
}

.loading {
    font-size: 24px;
}
</style>